import React from 'react'
import Layout from '../../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from '../../pages/e-books.module.css'

const MasculineMagnetismPage = () => {
  return (
    <Layout>
      <section className={`${styles.jumbo} jumbo h-52 sm:h-72`}>
        <div className="container">
          <h1 className="text-5xl sm:text-8xl text-red-800">Masculine Magnetism</h1>
        </div>
      </section>
      <section className="content pt-16">
        <div className="container mx-auto px-4 !max-w-[100%]">
          <div className="flex flex-col md:flex-row gap-12">
            <div className="w-full md:w-1/4">
              <StaticImage 
                src="../../images/masculine-magnetism-cover.jpg"
                alt="Masculine Magnetism Book Cover"
                className="w-full rounded-lg"
              />
            </div>
            <div className="w-full md:w-1/2">
              <div className="flex flex-col md:flex-row justify-between items-center gap-4 mb-4">
                <h2 className="text-3xl font-bold text-gray-900 !mt-0">This is Not Just a Personality Shift—This is a Transformation</h2>
                <a 
                  href="https://marciancx.gumroad.com/l/MM"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-red-800 text-white px-4 py-4 rounded-lg hover:bg-red-900 transition-colors whitespace-nowrap"
                >
                  Get your FREE copy!
                </a>
              </div>
              <div className="space-y-4">
                <p className="text-gray-700 text-base">
                  Charisma is not an inborn trait. It is a skill that can be built, developed, and mastered. 
                  The 30-Day Masculine Magnetism Protocol is designed to rewire your brain, body, and behavior 
                  to make confidence, presence, and influence your natural state. This program does not teach 
                  you to fake charisma—it installs it into your nervous system at a fundamental level.
                </p>
                <p className="text-gray-700 text-base">
                  The methods in this program are scientifically engineered for rapid and lasting results. 
                  Drawing from neuroscience, psychology, and real-world social mastery, this system employs 
                  neuroplasticity, deliberate practice, progressive social exposure, and reinforcement learning 
                  to permanently embed Masculine Magnetism into your personality.
                </p>
                <p className="text-gray-700 text-base mb-8">
                  This is not a theory. This is a field-tested framework that will transform the way you speak, 
                  move, and interact with others.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default MasculineMagnetismPage 